<!--  -->
<template>
  <div class="centerWrapper">
    <img
      src="../../assets/desk.png"
      style="width:18.2rem;height:6rem;margin-top:20px;margin: 0 auto;"
    />
    <van-button
      color="#2BB7B3"
      class="menuButton"
      @click="handleClickMenuButton('meetingList')"
      v-if="meetingListAuth"
    >
      <div class="menuButtonInnerWrapper">
        <span class="buttonTitle">会议工单</span>
        <span class="buttonTip">(查看所有工单以及运维进度)</span>
      </div>
    </van-button>

    <van-button
      color="#2BB7B3"
      class="menuButton"
      v-if="messageListAuth"
      @click="handleClickMenuButton('messageList')"
    >
      <div class="menuButtonInnerWrapper">
        <span class="buttonTitle">实时消息</span>
        <span class="buttonTip">(实时推送运维消息)</span>
      </div>
    </van-button>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {
    userAuthority() {
      return this.$store.state.user.authority
        ? JSON.parse(this.$store.state.user.authority)
        : [];
    },
    meetingListAuth() {
      let meetingListAutoObj = this.userAuthority.filter(item => {
        return item.permissionName === "会议工单";
      })[0];
      return meetingListAutoObj ? meetingListAutoObj.permissionType : false;
    },
    messageListAuth() {
      let meetingListAutoObj = this.userAuthority.filter(item => {
        return item.permissionName === "实时消息";
      });
      return meetingListAutoObj.length > 0;
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleClickMenuButton(path) {
      this.$store.commit("SetNavBarTitle", "会议工单");
      this.$router.push("/" + path);
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SetNavBarTitle", to.meta.name);
    next();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style>
/* @import url(); 引入公共css类*/
.menuButton {
  width: 18.2rem;
  margin: 0 auto;
  margin-top: 1rem;
  padding: 1.1rem 0;
  border-radius: 5px;
}
.menuButtonInnerWrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.buttonTitle {
  font-size: 0.8rem;
  color: white;
  margin-bottom: 0.1rem;
}
.buttonTip {
  font-size: 0.5rem;
  color: #a3e0de;
}
</style>
